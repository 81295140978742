<template>
    <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.0//EN" "http://www.w3.org/TR/2001/REC-SVG-20010904/DTD/svg10.dtd">
    <div class="icon-new-notification">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
            <path d="M9.35493 21.5C10.0601 22.1224 10.9863 22.5 12.0008 22.5C13.0152 22.5 13.9414 22.1224 14.6466 21.5M18.0008 8.5C18.0008 6.9087 17.3686 5.38258 16.2434 4.25736C15.1182 3.13214 13.5921 2.5 12.0008 2.5C10.4095 2.5 8.88333 3.13214 7.75811 4.25736C6.63289 5.38258 6.00075 6.9087 6.00075 8.5C6.00075 11.5902 5.22122 13.706 4.35042 15.1054C3.61588 16.2859 3.24861 16.8761 3.26208 17.0408C3.27699 17.2231 3.31561 17.2926 3.46253 17.4016C3.59521 17.5 4.19334 17.5 5.38961 17.5H18.6119C19.8082 17.5 20.4063 17.5 20.539 17.4016C20.6859 17.2926 20.7245 17.2231 20.7394 17.0408C20.7529 16.8761 20.3856 16.2859 19.6511 15.1054C18.7803 13.706 18.0008 11.5902 18.0008 8.5Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <span class="icon-title">{{title}}</span>
    </div>
</template>

<script>
    export default {
        props: {
            width: {
                type: String,
                required: false
            },
            height: {
                type: String,
                required: false
            },
            title: {
                type: String,
                required: false,
                default: ''
            }
        }
    };
</script>

<style scoped>
    .icon-new-notification {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        gap: 5px;

        &:hover{
            cursor: pointer;
        }
    }

    .icon-title {
        font-size: 15px;
    }
</style>